import {
  AuthenticateUserResponse,
  Employee,
  Merchant,
  MerchantPage,
  MerchantUserAttributes,
  Store,
} from '@bofrak-backend/shared';
import { atom } from 'recoil';
import { localPersistEffect } from './atom-effects';

export const isSignedInAtom = atom<boolean>({
  key: 'isSignedInAtom',
  default: false,
  effects_UNSTABLE: [localPersistEffect],
});

export const userAtom = atom<MerchantUserAttributes | null>({
  key: 'userAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const authAtom = atom<AuthenticateUserResponse | null>({
  key: 'authAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const encryptedCredentialsAtom = atom<string | null>({
  key: 'encryptedCredentialsAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const employeeAtom = atom<Employee | null>({
  key: 'employeeAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const merchantPageAtom = atom<MerchantPage | null>({
  key: 'merchantPageAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedMerchantAtom = atom<Merchant | null>({
  key: 'selectedMerchantAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedStoreAtom = atom<Store | null>({
  key: 'selectedStoreAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});
