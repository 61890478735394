import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { UserRole } from '@bofrak-backend/shared';
import { useState } from 'react';
import { AiOutlineProduct } from 'react-icons/ai';
import { BsBoxSeam, BsPersonVcard } from 'react-icons/bs';
import { GiShoppingCart } from 'react-icons/gi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoSettingsOutline } from 'react-icons/io5';
import { LiaPercentSolid } from 'react-icons/lia';
import { IconType } from 'react-icons/lib';
import { PiCashRegister } from 'react-icons/pi';
import { TfiReceipt } from 'react-icons/tfi';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuthenticator } from '../hooks/use-authenticator';
import {
  encryptedCredentialsAtom,
  isSignedInAtom,
  selectedStoreAtom,
  userAtom,
} from '../recoil/atoms';
import { envVars, paths } from '../utils/constants';
import StoreDisplay from './store';

interface AppName {
  name: string;
  url: string;
  icon: IconType;
  page?: string;
  allowedRoles: UserRole[];
}

const appNames: AppName[] = [
  {
    name: 'Reports',
    url: 'reports',
    icon: HiOutlineDocumentReport,
    allowedRoles: [UserRole.MANAGER, UserRole.ADMIN],
  },
  {
    name: 'Items',
    url: 'items-manager',
    icon: BsBoxSeam,
    allowedRoles: [UserRole.MANAGER, UserRole.ADMIN],
  },
  {
    name: 'Point of Sale',
    url: 'pos',
    icon: PiCashRegister,
    allowedRoles: [UserRole.MANAGER, UserRole.ADMIN, UserRole.CASHIER],
  },
  //{ name: 'POS Ext.', url: 'pos-extension', icon: FaCashRegister },
  {
    name: 'Inventory',
    url: 'inventory-manager',
    icon: AiOutlineProduct,
    allowedRoles: [
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.INVENTORY_COUNTER,
    ],
  },
  {
    name: 'Purchases',
    url: 'purchase-order-manager',
    icon: GiShoppingCart,
    allowedRoles: [UserRole.MANAGER, UserRole.ADMIN],
  },
  {
    name: 'Clients',
    url: 'clients-manager',
    icon: BsPersonVcard,
    allowedRoles: [UserRole.MANAGER, UserRole.ADMIN],
  },
  {
    name: 'Promotions',
    url: 'customer-loyalty',
    icon: LiaPercentSolid,
    allowedRoles: [
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.CASHIER,
      UserRole.INVENTORY_COUNTER,
      UserRole.SUPPLIER,
      UserRole.WAITER,
      UserRole.RECEIPT_CHECKER,
    ],
  },
  {
    name: 'Receipts',
    url: 'receipt-confirmation',
    icon: TfiReceipt,
    allowedRoles: [
      UserRole.CASHIER,
      UserRole.WAITER,
      UserRole.RECEIPT_CHECKER,
      UserRole.MANAGER,
      UserRole.ADMIN,
    ],
  },
  {
    name: 'Settings',
    url: 'settings',
    icon: IoSettingsOutline,
    allowedRoles: [UserRole.MANAGER, UserRole.ADMIN],
  },
];

export const DashboardPage = () => {
  const domain = `shopnsmile.org`;

  const toast = useToast();
  const navigate = useNavigate();
  const stage = envVars.STAGE;
  const encryptedCredentials = useRecoilValue(encryptedCredentialsAtom);
  const user = useRecoilValue(userAtom);
  const isSignedIn = useRecoilValue(isSignedInAtom);
  const [selectedStore, setSelectedStore] = useRecoilState(selectedStoreAtom);
  const { signOut, isLoading } = useAuthenticator();
  const [port, setPort] = useState('');

  const allowedApps = appNames;

  const handleClickBack = () => {
    setSelectedStore(null);
    navigate(paths.home);
  };

  return (
    <Flex
      minH={'100vh'}
      align={'flex-start'}
      width={'full'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} width={'100%'} py={2} px={6}>
        <Flex
          justify={'center'}
          direction={'column'}
          align={'center'}
          width={'100%'}
          gap={4}>
          <Heading textAlign={'center'}>Hello {user?.given_name}</Heading>
          {!stage.includes('prod') && selectedStore && (
            <VStack>
              <InputGroup size="md">
                <Input
                  onChange={(e) => {
                    setPort(e.target.value);
                  }}
                  pr="4.5rem"
                  type="text"
                  placeholder="Dev Mode Port (e.g 5174)"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      if (!encryptedCredentials) {
                        toast({
                          title: 'Sign In First',
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        });
                        return;
                      }

                      if (!selectedStore?.id) {
                        toast({
                          title: 'Select a store first',
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        });
                        return;
                      }

                      window.location.href = `http://localhost:${port}?token=${encodeURIComponent(encryptedCredentials)}&store_id=${selectedStore.id}`;
                    }}>
                    Go
                  </Button>
                </InputRightElement>
              </InputGroup>
            </VStack>
          )}
          {selectedStore && (
            <Flex
              justify={'start'}
              pl={5}
              align={'center'}
              width={'100%'}
              gap={4}>
              {/* back button */}
              <Button size={'sm'} onClick={handleClickBack}>
                Back
              </Button>
            </Flex>
          )}
        </Flex>
        <Box
          width={'full'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={'column'}
          minHeight={'70vh'}>
          {!selectedStore && <StoreDisplay />}
          {selectedStore?.id && (
            <SimpleGrid columns={2} spacing={5}>
              {allowedApps.map((app, i) => (
                <Box
                  cursor={'pointer'}
                  key={app.url + i}
                  textAlign={'center'}
                  onClick={() => {
                    const localDomain = app.page ? domain + app.page : domain;

                    if (!selectedStore?.id) {
                      toast({
                        title: 'Select a store first',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                      });

                      return;
                    }

                    if (encryptedCredentials && user && isSignedIn) {
                      window.location.href = `https://${app.url}.${stage}.${localDomain}?token=${encodeURIComponent(encryptedCredentials)}&store_id=${selectedStore?.id}`;
                    } else {
                      toast({
                        title: 'Sign In First',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                      });

                      navigate(paths.home);
                    }
                  }}>
                  <Box
                    p={4}
                    textTransform={'capitalize'}
                    bg="gray.100"
                    boxShadow="xl"
                    borderRadius={8}>
                    <Icon as={app.icon} fontSize="48px" />
                    <Text fontSize={'large'} fontWeight={'bold'}>
                      {app.name}
                    </Text>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          )}
          <Flex justify={'center'} align={'center'} width={'100%'} gap={4}>
            <Button
              my={5}
              width={'50%'}
              bg={'red.500'}
              isLoading={isLoading}
              onClick={async () => {
                await signOut();
                setSelectedStore(null);
                navigate(paths.home);
              }}>
              Sign Out
            </Button>
          </Flex>
        </Box>
      </Stack>
    </Flex>
  );
};
