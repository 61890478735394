import { Text, VStack } from '@chakra-ui/react';
import { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    console.log('Error:', JSON.stringify({ error }, null, 2));
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can log the error and errorInfo here for reporting purposes
    console.error('Uncaught error:', error, errorInfo);
    console.log('Error Info:', { errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <VStack
            align={'center'}
            justify={'center'}
            width={'100%'}
            height={'100%'}
            bg={'lightBackground'}
            position="fixed"
            top={'0'}
            bottom={'0'}
            p={5}
            zIndex={100000}>
            <Text fontSize={'54px'} fontWeight={'black'} color={'red.400'}>
              {' '}
              Oops!{' '}
            </Text>
            <Text fontSize={'md'} fontWeight={'medium'}>
              {' '}
              Something went wrong{' '}
            </Text>
            <Text fontSize={'xs'} fontWeight={'normal'} textAlign={'center'}>
              {' '}
              Please call{' '}
              <Text as={'a'} fontWeight={'bold'} href={'tel:+260975356099'}>
                +258873038874
              </Text>{' '}
              for assistance.
            </Text>
          </VStack>
        )
      );
    } else return this.props.children;
  }
}
