import { Route, Routes, useNavigate } from 'react-router-dom';
import { DashboardPage } from './pages/Dashboard';
import { HomePage } from './pages/HomePage';
import { NotFoundPage } from './pages/NotFoundPage';
import { paths } from './utils/constants';
import { EmailVerified } from './pages/EmailVerified';
import { Register } from './pages/Register';
import NewStore from './pages/store/NewStore';

function App() {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route path={paths.home} element={<HomePage />} />
      <Route path={paths.dashboard} element={<DashboardPage />} />
      <Route path={paths.register} element={<Register />} />
      <Route
        path={paths.newStore}
        element={
          <NewStore
            onClose={() => {
              navigate(paths.dashboard);
            }}
          />
        }
      />
      <Route path={paths.emailVerification} element={<EmailVerified />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    /*  */
  );
}

export default App;
