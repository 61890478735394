import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useAuthenticator } from '../hooks/use-authenticator';
import { selectedStoreAtom } from '../recoil/atoms';
import { paths } from '../utils/constants';
import ShopAndSmileLogo from '../components/resuable/Logo';

export const HomePage = () => {
  const toast = useToast();

  const [pin, setPin] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [show, setShow] = useState(false);
  const {
    signIn,
    isSignedIn,
    error,
    isLoading,
    encryptedCredentials: encrypted, //#endregion
    signOut,
  } = useAuthenticator();
  const navigate = useNavigate();
  const setCurrentStore = useSetRecoilState(selectedStoreAtom);

  useEffect(() => {
    const shouldSignOut = new URLSearchParams(window.location.search).get(
      'signOut',
    );

    if (shouldSignOut) {
      signOut();
      setCurrentStore(null);
    } else if (isSignedIn && encrypted) {
      if (shouldSignOut) {
        signOut();
      } else {
        navigate(paths.dashboard);
      }
    } else {
      setCurrentStore(null);
    }
  }, [isSignedIn, encrypted]);

  return (
    <Flex
      minH={'100vh'}
      align={'flex-start'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <ShopAndSmileLogo />
          {!error ? (
            <Text fontSize={'md'} color={'gray.600'}>
              Sign In to your account✌️
            </Text>
          ) : (
            <Text fontSize={'md'} color={'red.600'}>
              {error}
            </Text>
          )}
        </Stack>

        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <InputGroup size="md">
              <Input
                disabled={isSignedIn}
                pr="4.5rem"
                type={'text'}
                placeholder="+258XXXXXXXXX"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="md">
              <Input
                disabled={isSignedIn}
                pr="4.5rem"
                type={show ? 'text' : 'password'}
                placeholder="Enter PIN"
                onChange={(e) => setPin(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Stack spacing={10}>
              {!isSignedIn ? (
                <Button
                  bg={'blue.400'}
                  isLoading={isLoading}
                  onClick={async () => {
                    if (!phoneNumber || !pin) {
                      toast({
                        title: 'Invalid Inputs',
                        description:
                          'Please enter a valid phone number and pin',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                      });
                      return;
                    }

                    await signIn(phoneNumber, pin);
                  }}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Sign in
                </Button>
              ) : (
                <Button
                  bg={'red.400'}
                  isLoading={isLoading}
                  onClick={async () => {
                    await signOut();
                    setCurrentStore(null);
                  }}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}>
                  Sign Out
                </Button>
              )}

              {
                // Register link and forgot password link
              }

              <Stack spacing={4}>
                <Text
                  fontSize={'sm'}
                  cursor={'pointer'}
                  color={'blue.400'}
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => navigate(paths.register)}>
                  Don't have an account? Register here
                </Text>

                <Text
                  fontSize={'sm'}
                  cursor={'pointer'}
                  color={'blue.400'}
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => navigate(paths.forgotPassword)}>
                  Forgot your password? Reset here
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};
