import { Button, Flex, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

export const NotFoundPage = () => {
  return (
    <Flex height="100vh" align="center" justify="center" direction="column">
      <Heading textAlign={'center'} mb={4}>
        You are lost!
      </Heading>
      <Button as={Link} to="/" colorScheme="green">
        Go to Home Page
      </Button>
    </Flex>
  );
};
