import { Center, Image } from '@chakra-ui/react';

import image from '../../assets/sns.svg';

const ShopAndSmileLogo = ({ width }: { width?: number }) => {
  return (
    <Center>
      <Image
        objectFit="cover"
        {...(width && { width })}
        src={image}
        alt="shop and smile logo"
      />
    </Center>
  );
};

export default ShopAndSmileLogo;
